<template>
  <ReportBase title="Standard Responses" :is-loading="loading" class="standard-responses">
    <template #content>
      <TableContent :columnDefs="tableContent.columnDefs" :defaultColDef="tableContent.defaultColDef"
        :rowData="tableContent.rowData" />
    </template>
  </ReportBase>
</template>

<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";
import TableContent from '@/components/report/tabular/TableContent.vue';
import ReportBase from '@/components/report/tabular/ReportBase';
import { responseToTable } from '@/components/report/tabular/cubeDataHandler';

export default {
  name: 'StandardResponses',

  mixins: [NotifyMixin, CubeRequestMixin],

  components: {
    ReportBase,
    TableContent
  },

  data() {
    return {
      loading: true,
      tableContent: null,
    }
  },

  methods: {
    async fetchReports() {
      this.loading = true;

      try {
        const response = await this.fetchCubeData('DHR01');

        this.tableContent = responseToTable(response);
        this.loading = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    }
  },

  mounted() {
    this.fetchReports();
  },
};

</script>

<style scoped>

</style>